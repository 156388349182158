/* import __COLOCATED_TEMPLATE__ from './check-installation-new.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import ajax from 'embercom/lib/ajax';

interface PingDomainResponse {
  domain: string;
  successful_last_request: boolean;
  errors_count: number;
}

interface Args {
  logsType: string;
  logsPlatform: string;
  checkInstallationText?: string;
  parentPingDomainsCount?: number;
  onPingDomainsCountChange?: (count: number) => void;
  onPingDomainsUnresolvedErrorCountChange?: (count: number) => void;
}

interface Signature {
  Args: Args;
}

export default class CheckInstallationNew extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare intercomEventService: $TSFixMe;

  @tracked errorMessage: string | null = null;
  @tracked pingDomains: PingDomainResponse[] = [];
  @tracked openSectionId: string | null = null;
  @tracked pingErrorsLoaded = false;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.fetchPingDomains('boot');
  }

  get app() {
    return this.appService.app;
  }

  get showPingDomains() {
    return this.pingDomains.length > 0;
  }

  @action
  async fetchPingDomains(fetchType: 'boot' | 'manual' = 'manual'): Promise<void> {
    this.pingErrorsLoaded = false;
    try {
      let response = await ajax({
        url: '/ember/ping_domain_errors/list',
        type: 'GET',
        data: {
          app_id: this.app.id,
          type: this.args.logsType,
          platform: this.args.logsPlatform,
        },
      });
      this.pingDomains = response.items;

      // update the parent ping domains success count
      if (this.args.onPingDomainsCountChange) {
        this.args.onPingDomainsCountChange(this.pingDomains.length);
      }

      // update the parent ping domains unresolved error count
      if (this.args.onPingDomainsUnresolvedErrorCountChange) {
        let errorCount = this.pingDomains.filter(
          (domain) => domain.errors_count > 0 && domain.successful_last_request === false,
        ).length;
        this.args.onPingDomainsUnresolvedErrorCountChange(errorCount);
      }

      if (fetchType === 'manual') {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'check_idv_installation',
          place: 'installation-new',
          section: 'settings',
          platform: this.args.logsPlatform,
          logs_type: this.args.logsType,
        });
      }
    } catch (error) {
      console.error('Error fetching ping domains:', error);
      this.errorMessage = this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.failed-to-fetch-ping-domains',
      );
    } finally {
      this.pingErrorsLoaded = true;
    }
  }

  get errorsAreLoaded() {
    return this.pingErrorsLoaded;
  }

  accordionIdForDomain(domain: string) {
    return `section_domain_${domain.replace(/[^a-zA-Z0-9]/g, '_')}`;
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  refreshLogs() {
    this.fetchPingDomains('manual');
  }

  @action
  displayErrorsCount(pingDomain: PingDomainResponse) {
    let translatedError = this.intl.t(
      'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.error',
    );
    let translatedErrors = this.intl.t(
      'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.errors',
    );
    if (pingDomain.successful_last_request) {
      translatedError = this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.past-error',
      );
      translatedErrors = this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.past-errors',
      );
    }

    if (pingDomain.errors_count > 100) {
      return `100+ ${translatedErrors}`;
    }

    if (pingDomain.errors_count === 1) {
      return `${pingDomain.errors_count} ${translatedError}`;
    }
    return `${pingDomain.errors_count} ${translatedErrors}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::CheckInstallationNew': typeof CheckInstallationNew;
    'installation-new/web/idv-setup/check-installation-new': typeof CheckInstallationNew;
  }
}
